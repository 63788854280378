<template>
	<div id="serviceDescription">
    <img class="serviceDescriptionImg" :src='url'/>
	</div>
</template>

<script>
import { validApp, getUrlParam, setCookie, getCookie, delCookie } from '../../lib/utils';
import { Dialog, Toast } from 'vant';
import { trafficServiceDesc } from '@/server/request'

export default {
	data() {
		return {
			url: ''
		}
	},
	created() {
		document.title = '服务介绍'
		let params = ''
		trafficServiceDesc(params).then(res=>{
			if(res.status=='200'){
        this.url = this.showgroupImage(res.data.imgUrl)
			}else{
				Toast.fail({
					message: res.msg,
					duration:'3000'
				});
			}
		})
	},
	mounted() {
		
	},
	computed: {
		
	},
	methods: {
    showgroupImage(data) {
      return data + '?Authorization=' + getCookie('CWTOKEN')
    },
  },
};
</script>

<style>
html,body{
  height: 100%;
}
#app{
  height: 100%;
}
#serviceDescription{
  padding: 0.4rem;
}
.serviceDescriptionImg{
  height: 100%;
  width: 100%;
}
</style>
